import { defineStore } from 'pinia'

export const useCurrentRoleStore = defineStore('currentRoleStore', {
  state: () => ({
    currentRole: {} as SchoolRole,
  }),
  actions: {
    setCurrentRole(role: SchoolRole) {
      this.currentRole = role
    },
  },
})
